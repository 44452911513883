a.btn-choose1 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(76, 111, 217);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose2 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 172, 149);
  color: #505050;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose3 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(162, 255, 250);
  color: #505050;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose4 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 195, 255);
  color: #505050;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose5 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(138, 0, 34);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose9 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 232, 80);
  color: #505050;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose10 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(34, 216, 206);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose11 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(153, 167, 180);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose12 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(112, 88, 163);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose13 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 51);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose14 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 105, 151);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose15 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(245, 216, 146);
  color: #505050;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose16 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 10, 133);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose17 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(177, 132, 153);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose18 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(112, 189, 255);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose19 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(241, 131, 0);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose20 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(195, 216, 37);
  color: #505050;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose21 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(154, 13, 124);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose22 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(104, 169, 207, 0.9);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose23 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 205, 51);
  color: #505050;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose24 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(148, 215, 199);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose25 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 230, 244);
  color: #505050;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose26 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(237, 66, 255);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose27 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(197, 237, 253);
  color: #505050;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose28 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(199, 79, 48);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose29 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(253, 245, 230);
  color: #505050;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose30 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 191, 255);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose31 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(137, 201, 151);
  color: #505050;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose32 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 201, 147);
  color: #505050;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose33 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(179, 34, 34);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose34 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(192, 168, 132);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose35 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(250, 192, 217);
  color: #505050;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose36 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 250, 150);
  color: #505050;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose37 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(42, 61, 32);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose38 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(107, 142, 35);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose39 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(150, 0, 67);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose40 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(227, 247, 205);
  color: #000;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose41 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(219, 255, 163);
  color: #000;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose42 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(201, 23, 30);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose44 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 177, 205);
  color: #000;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose45 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 109, 77);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose46 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(46, 46, 46);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose47 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(252, 232, 230);
  color: #000;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose48 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 223, 184);
  color: #000;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose49 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(196, 247, 116);
  color: #000;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose50 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 215, 171);
  color: #000;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose51 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(73, 227, 190);
  color: #000;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose52 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(220, 188, 227);
  color: #000;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose53 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(10, 63, 148);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose54 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(143, 93, 150);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose55 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(246, 189, 255);
  color: #000;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose43 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(141, 160, 182);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose56 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(246, 191, 188);
  color: #000;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose59 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(163, 6, 37);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose60 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(250, 188, 112);
  color: #000;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose57 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(227, 211, 242);
  color: #000;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}

a.btn-choose61 {
  display: inline-flex;
  width: 200px;
  height: 80px;
  font-size: 18px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(144, 139, 191);
  color: #fff;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
}