a.btn {
  display: inline-flex;
  width: 200px;
  height: 80px;
  margin: 30px 30px 20px;
  padding: 15px 10px;
  font-size: 18px;
  border: 1px solid #505050;
  background: white;
  color: #505050;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;
  border-bottom: solid 4px #505050;
  z-index: 1;
  position: relative;
}
a.btn:active {
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  border-bottom: none;
}

a.effect1:hover {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(76, 111, 217);
  color: #fff;
  border-bottom: solid 4px #505050;
}

a.effect2:hover {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 172, 149);
  color: #505050;
  border-bottom: solid 4px #505050;
}
a.effect3:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(162, 255, 250);
  color: #505050;
  border-bottom: solid 4px #505050;
}
a.effect4:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(255, 195, 255);
  color: #505050;
  border-bottom: solid 4px #505050;
}
a.effect5:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(138, 0, 34);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect9:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(255, 232, 80);
  color: #505050;
  border-bottom: solid 4px #505050;
}
a.effect10:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(34, 216, 206);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect11:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(153, 167, 180);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect12:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(112, 88, 163);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect13:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(0, 0, 51);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect14:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(255, 105, 151);
  color: #fff;
  border-bottom: solid 4px #505050;
}

a.effect15:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(245, 216, 146);
  color: #505050;
  border-bottom: solid 4px #505050;
}
a.effect16:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(255, 10, 133);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect17:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(177, 132, 153);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect18:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(112, 189, 255);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect19:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(241, 131, 0);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect20:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(195, 216, 37);
  color: #505050;
  border-bottom: solid 4px #505050;
}
a.effect21:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(154, 13, 124);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect22:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(104, 169, 207);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect23:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(255, 205, 51);
  color: #505050;
  border-bottom: solid 4px #505050;
}
a.effect24:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(148, 215, 199);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect25:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(255, 230, 244);
  color: #505050;
  border-bottom: solid 4px #505050;
}
a.effect26:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(237, 66, 255);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect27:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(197, 237, 253);
  color: #505050;
  border-bottom: solid 4px #505050;
}
a.effect28:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(199, 79, 48);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect29:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(253, 245, 230);
  color: #505050;
  border-bottom: solid 4px #505050;
}
a.effect30:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(0, 191, 255);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect31:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(137, 201, 151);
  color: #505050;
  border-bottom: solid 4px #505050;
}
a.effect32:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(255, 201, 147);
  color: #505050;
  border-bottom: solid 4px #505050;
}
a.effect33:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(179, 34, 34);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect34:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(192, 168, 132);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect35:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(250, 192, 217);
  color: #505050;
  border-bottom: solid 4px #505050;
}
a.effect36:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(255, 250, 150);
  color: #505050;
  border-bottom: solid 4px #505050;
}
a.effect37:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(42, 61, 32);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect38:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(107, 142, 35);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect39:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(150, 0, 67);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect40:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(227, 247, 205);
  color: #000;
  border-bottom: solid 4px #505050;
}
a.effect41:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(219, 255, 163);
  color: #000;
  border-bottom: solid 4px #505050;
}
a.effect42:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(201, 23, 30);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect44:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(255, 177, 205);
  color: #000;
  border-bottom: solid 4px #505050;
}
a.effect45:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(0, 109, 77);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect46:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(46, 46, 46);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect47:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(252, 232, 230);
  color: #000;
  border-bottom: solid 4px #505050;
}
a.effect48:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(255, 223, 184);
  color: #000;
  border-bottom: solid 4px #505050;
}
a.effect49:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(196, 247, 116);
  color: #000;
  border-bottom: solid 4px #505050;
}
a.effect50:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(255, 215, 171);
  color: #000;
  border-bottom: solid 4px #505050;
}
a.effect51:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(73, 227, 190);
  color: #000;
  border-bottom: solid 4px #505050;
}
a.effect52:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(220, 188, 227);
  color: #000;
  border-bottom: solid 4px #505050;
}
a.effect53:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(10, 63, 148);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect54:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(143, 93, 150);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect55:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(246, 189, 255);
  color: #000;
  border-bottom: solid 4px #505050;
}
a.effect43:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(141, 160, 182);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect56:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(246, 191, 188);
  color: #000;
  border-bottom: solid 4px #505050;
}
a.effect59:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(163, 6, 37);
  color: #fff;
  border-bottom: solid 4px #505050;
}
a.effect60:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(250, 188, 112);
  color: #000;
  border-bottom: solid 4px #505050;
}
a.effect57:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(227, 211, 242);
  color: #000;
  border-bottom: solid 4px #505050;
}
a.effect61:hover {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: rgba(144, 139, 191);
  color: #fff;
  border-bottom: solid 4px #505050;
}